import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMenuLogo = lazy(
  () => import("./menu-logo-D8bcnt1_.js").then((module) => ({
    default: module.MenuLogo
  }))
);
function MenuLogo({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMenuLogo, { ...props });
}
const useMenuLogoContent = () => {
  return useMemo(
    () => ({
      menuLogo: {
        component: MenuLogo
      }
    }),
    []
  );
};
export {
  LazyMenuLogo as MenuLogo,
  useMenuLogoContent
};
